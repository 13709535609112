<template>
  <div class="scheduleAdmin">
    <!-- {{slots}} -->
    <div class="scheduleContainer">
      <div class="headerSchedule">
        <div class="nestedHeaderSchedule" v-if="!isMobile">
          <template v-for="(day, index) in days">
            <div v-if="day === ''" :key="index" />
            <template v-else>
              <div :key="index" class="contentHeaderSchedule">
                <h3>
                  {{ day }}
                </h3>
              </div>
            </template>
          </template>
        </div>
        <a-select v-else :value="dayInputMobile" size="large" placeholder="Choose Day" class="mb-3"
          style="width: 100%; height: 48px;" @change="changeDayInput">
          <template v-for="(day, index) in days">
            <a-select-option v-if="day !== ''" :value="index === 5 ? 0 : index" :key="index">{{ day }}</a-select-option>
          </template>
        </a-select>
      </div>
      <div class="timeSchedule" :style="inlineStyleTimeSchedule">
        <template v-for="(time, idx) in times">
          <div style="display: flex; align-items:center;" :key="idx" v-if="time.type === 'istirahat'">
            <p style="text-align:right; color: #707788;">BREAK ({{ time.startAt }})</p>
          </div>
          <div v-else class="scheduleAndTime time" :key="idx">
            <div>
              <h3>{{ time.startAt }}</h3>
            </div>
          </div>
        </template>
      </div>
      <div class="schedules" :style="{
        'grid-template-columns': isMobile ? '' : 'repeat(5, 20%)',
        'grid-template-rows': isMobile ? 'repeat(10, 10%)' : ''
      }">
        <template v-if="times.length">
          <template v-for="n in isMobile ? slotAmount.mobile : slotAmount.desktop">
            <SlotSchedule :dataGuru="dataGuru" :times='times' :isEmpty="checkSlot(n).isEmpty" :desc="checkSlot(n).desc"
              :key="n" :n='n' :class="['scheduleAndTime', 'slotSchedule']" :slots='dayToIndexSlots' :isMobile="isMobile"
              :dayInputMobile="dayInputMobile" :modalVisible="modalVisible" @fetchScheduleTeacher="fetchScheduleTeacher"
              :viewOnly="viewOnly" :type="type" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import SlotSchedule from '@/components/app/Admin/BoxScheduleAdmin/SlotSchedule'
export default {
  name: 'BoxScheduleAdmin',
  components: {
    SlotSchedule,
  },
  props: ['slots', 'week', 'dataGuru', 'modalVisible', 'model', 'viewOnly', 'type'],
  data() {
    return {
      dayInputMobile: 1,
      days: ['', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat'],
      hariIniNumDiffWithMonday: moment().diff(moment().startOf('isoWeek'), 'days') + 1,
      times: [],
      start_class: null,
      slotAomunt: {
        mobile: 0,
        desktop: 0,
      },
      inlineStyleTimeSchedule: {
        'grid-template-rows': '',
      },
    }
  },
  methods: {
    changeDayInput(e) {
      this.dayInputMobile = e
    },
    checkSlot(position) {
      // console.log(position)
      let isEmpty = true
      let id
      const realPosition = this.isMobile ? position : Math.ceil(position / 5)
      const day = this.isMobile ? this.dayInputMobile : position % 5
      let jamPelajaran
      const time = this.times.find(time => time.position === realPosition)
      this.dayToIndexSlots.forEach(slot => {
        slot.sesis.forEach(sesi => {
          if ((!this.isMobile && position % 5 === slot.hari && realPosition === slot.posisi) ||
            (this.isMobile && this.dayInputMobile === slot.hari && realPosition === slot.posisi)) {
            jamPelajaran = slot.jam_pelajaran
            isEmpty = false
            id = slot.id
          }
        })
      })
      // const minusBreak = realPosition > 5 ? 10 : 0
      return {
        isEmpty,
        desc: {
          id,
          jamPelajaran,
          position: realPosition,
          day,
          // jamMulai: moment(this.modelJadwal.jam_mulai, 'HH:mm').add(((realPosition - 1) * this.modelJadwal.durasi) - minusBreak, 'minutes').format('HH:mm'),
          jamMulai: time.startAt,
          jamSelesai: time.endAt,
          typeModel: time.type,
          // durasi: this.modelJadwal.durasi,
          tipe_model_jadwal: this.model.name,
        },
      }
    },
    fetchScheduleTeacher() {
      this.$emit('fetchScheduleTeacher')
    },
  },
  created() {
    const modelJadwals = this.model.model_jadwals.sort((a, b) => a - b)
    for (let i = 0; i < modelJadwals.length; i++) {
      const modelJadwal = modelJadwals[i]
      const startAt = moment(modelJadwal.jam_mulai, 'HH:mm:ss').format('HH:mm')
      const endAt = moment(modelJadwal.jam_selesai, 'HH:mm:ss').format('HH:mm')
      this.times.push({
        startAt,
        endAt,
        type: modelJadwal.tipe_jam,
        position: i + 1,
      })
      // console.log(this.times)
      this.inlineStyleTimeSchedule['grid-template-rows'] += '1fr '
    }
    this.slotAmount = {
      mobile: this.times.length * 1,
      desktop: this.times.length * 5, // amount of day
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    dayToIndexSlots() {
      let newSlot = []
      if (this.slots.length > 0) {
        newSlot = this.slots.map(slot => {
          switch (slot.hari) {
            case 'Senin':
              slot.hari = 1
              break
            case 'Selasa':
              slot.hari = 2
              break
            case 'Rabu':
              slot.hari = 3
              break
            case 'Kamis':
              slot.hari = 4
              break
            case 'Jumat':
              slot.hari = 0
              break
            default:
              slot.hari = -1
              break
          }
          return slot
        })
      }
      return newSlot
    },
    todayDate() {
      return moment().locale('id').format('DD MMMM YYYY')
    },
    // modelJadwal() {
    //   let modelJadwal
    //   for (let i = 0; i < this.slots.length; i++) {
    //     if (this.slots[i].posisi === 1) {
    //       modelJadwal = this.slots[i].model_jadwal
    //       break
    //     }
    //   }

    //   if (modelJadwal) {
    //     this.$store.commit('user/SET_STATE', {
    //       modelJadwal,
    //     })
    //     return modelJadwal
    //   } else {
    //     return this.$store.state.user.modelJadwal
    //   }
    // },
  },
  watch: {
    isMobile(newVal) {
      if (!newVal) {
        this.dayInputMobile = 1
      }
    },
  },
}
</script>
<style lang="scss">
.scheduleAdmin {
  min-height: 500px;

  .subH3 {
    font-family: "Mukta", "sans-serif";
    font-weight: 600;
    font-size: 14px;
    color: #041930;
  }

  .scheduleContainer {
    display: grid;
    grid-template-columns: 60px;
    /* grid-template-rows: 10vh repeat(5, 20vh); */
    /* grid-template-areas: "header header"
    "sidebar main"; */
    justify-items: center;
    align-items: center;
    /* padding: 20px; */
  }

  .headerSchedule {
    /* grid-area: header; */
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    width: 100%;
    /* grid-column: ; */
  }

  .nestedHeaderSchedule {
    display: grid;
    grid-template-columns: 5% repeat(5, 19%);
    /* justify-items: center; */
    align-items: center;
    padding-bottom: 10px;
  }

  .contentHeaderSchedule {
    text-align: center;
  }

  .timeSchedule {
    display: grid;
    width: 100%;
    justify-items: end;
    padding-right: 15px;
  }

  .schedules {
    display: grid;
    /* grid-template-columns: repeat(5, 20%); */
    width: 100%;
    height: 100%;
    justify-items: center;
    background-color: #ebf0f9;
  }

  .scheduleAndTime {
    min-height: 86.5px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .contentBoxSlot {
    display: flex;
  }

  .slotSchedule {
    padding: 2.5px;
    border: 1px solid #d3d9e6;
  }

  .time {
    padding-top: 5px;
  }
}
</style>
